<button mat-icon-button [matMenuTriggerFor]="settings">
	<mat-icon class="fill">settings</mat-icon>
</button>

<mat-menu #settings="matMenu">
	@for (headerItem of settingsItems(); track headerItem.title) {
		<button mat-menu-item (click)="headerItem.action()">
			<mat-icon [class.fill]="headerItem.iconFill">{{ headerItem.icon }}</mat-icon>
			<span>{{ headerItem.title }}</span>
		</button>
	}
</mat-menu>
