<mat-sidenav-container>
	<mat-sidenav #sidenav mode="over">
		<app-sidebar/>
	</mat-sidenav>

	<mat-sidenav-content>
		<app-header/>

		<section class="app-content">
			@if (backgroundEnabled()) {
				<app-background/>
			}
			<router-outlet/>
			<app-footer/>
		</section>
	</mat-sidenav-content>
</mat-sidenav-container>
