<base-dialog [configuration]="dialogConfiguration()">
	<main>
		<p><dyslexic-text text="
			Have you recently wondered whether you have dyslexia?
		"/></p>
		<p><dyslexic-text text="
			Every once in a while you look at a page, and you could swear that the words are moving on you.
			But you blink and its gone. Those slippery words.
		"/></p>
		<p><dyslexic-text text="
			Well, I’m happy to have bamboozled you.
		"/></p>

		<mat-checkbox class="mat-menu-item" color="primary"
					  [formControl]="enabledControl"
					  (change)="toggleMovingBackgroundEnabled()">
			{{ enabled() ? 'Dyslexic Text' : 'Yeah nah' }}
		</mat-checkbox>

		@if (enabled()) {
			<p><dyslexic-text text="Yeah make it worse, why not. Might as well, while you’re here."/></p>
		} @else {
			<p>Oh you have your own dyslexia? Why didn't you say so earlier?</p>
		}

		<mat-slider showTickMarks color="primary" [min]="minAmount" [max]="maxAmount" step="1"
					[disabled]="!enabled()">

			<input matSliderThumb [formControl]="amountControl"
				   (change)="setMovingBackgroundAmount()">
		</mat-slider>
	</main>
</base-dialog>
