<section class="font-info serif">
	<h6>EB Garamond</h6>
	<a href="https://github.com/octaviopardo/EBGaramond12">&copy; 2017 The EB Garamond Project Authors</a>
	<a href="https://openfontlicense.org">SIL Open Font License, Version 1.1</a>
</section>

<section class="font-info sans-serif">
	<h6>Lato</h6>
	<a href="https://www.latofonts.com">&copy; 2010 Łukasz Dziedzic</a>
	<a href="https://openfontlicense.org">SIL Open Font License, Version 1.1</a>
</section>

<section class="font-info monospace">
	<h6>Victor Mono</h6>
	<a href="https://github.com/rubjo/victor-mono">&copy; 2021 Rune Bjørnerås</a>
	<a href="https://openfontlicense.org">SIL Open Font License, Version 1.1</a>
</section>
