<div [style.background-color]="palette().colorDefault"></div>

<div [style.background-color]="palette().colorDark"></div>

<div class="mock-sidebar" [style.background-color]="palette().colorLighter">
	<mat-icon>circle</mat-icon>
	<mat-icon>circle</mat-icon>
	<mat-icon>circle</mat-icon>
</div>

<div class="mock-content">
	<strong [style.color]="palette().colorDefault">{{ palette().displayName }} Palette</strong>
</div>
