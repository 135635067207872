export enum InternalSvgIcon {
	GOAT = 'goat',
	GONG = 'gong',
	ONI = 'oni',
	SET_UTILITIES = 'set-utilities',
	SNAKE = 'snake',
	SPRINTER = 'sprinter',
	STACK = 'stack',
	WHEEL = 'wheel',
}
