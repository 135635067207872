<mat-toolbar>
	<button mat-icon-button (click)="toggleSidenav($event)">
		<mat-icon>menu</mat-icon>
	</button>

	<span class="title-wrapper">
		<dyslexic-text text="inanity"/>
	</span>
</mat-toolbar>

<section class="sidebar-content">
	<section class="nav-items">
		@for (navigationItem of navigationItems(); track $index) {
			<sidebar-item [navigationItem]="navigationItem"/>
		}
	</section>

	<fonts/>
</section>

<mat-toolbar>
	<a mat-icon-button [href]="githubLink">
		<mat-icon [svgIcon]="GitHubIcon"/>
	</a>

	<span class="github-wrapper">
		<span>v{{ version }}</span>

		<a [href]="githubLink">
			<dyslexic-text text="inanity github"/>
		</a>
	</span>
</mat-toolbar>
